<template>
  <DataTable :value="rows" class="p-datatable-small" tableStyle="min-width: 50rem">
    <Column  header="Recepción técnica" style="font-size: 0.75rem">
      <template #body="{ data }">
        <div class="flex flex-row gap-2">
          <strong class="mr-2">
            Identificador
          </strong>
          <span class="text-xs underline text-blue-600 cursor-pointer" @click="OnClickEnvio(data.Id)">
            {{ data.Id }}
          </span>
        </div>
        <div class="flex flex-row gap-2">
          <strong>
            Fecha de contablización
          </strong>
          <span class="text-xs">
            {{ data.FechaContabilizacion }}
          </span>
        </div>
      </template>
    </Column>
    <Column  header="Control Envio" style="font-size: 0.75rem">
      <template #body="{ data }">
        <div class="flex flex-row gap-2">
          <strong class="mr-2">
            # Envio / Guia
          </strong>
          <span class="text-xs">
            {{ data.EnvioId }} / {{ data.Guia }}
          </span>
        </div>
        <div class="flex flex-row gap-2">
          <strong>
            Fecha de Envio
          </strong>
          <span class="text-xs">
            {{ data.FechaEnvio }}
          </span>
        </div>
      </template>
    </Column>
    <Column  header="Bodega" style="font-size: 0.75rem">
      <template #body="{ data }">
        <div class="flex flex-row gap-2">
          <strong class="mr-2">
            Codigo
          </strong>
          <span class="text-xs">
            {{ data.WhsCode }}
          </span>
        </div>
        <div class="flex flex-row gap-2">
          <strong>
            Nombre
          </strong>
          <span class="text-xs">
            {{ data.WhsName  }}
          </span>
        </div>
      </template>
    </Column>
    <Column  header="Usuarios" style="font-size: 0.75rem">
      <template #body="{ data }">
        <div class="flex flex-row gap-2">
          <strong class="mr-2">
            Enviado por
          </strong>
          <span class="text-xs">
            {{ data.UsuarioEnvio }}
          </span>
        </div>
        <div class="flex flex-row gap-2">
          <strong>
            Recibido por
          </strong>
          <span class="text-xs">
            {{ data.UsuarioRecibe  }}
          </span>
        </div>
      </template>
    </Column>
  </DataTable>
</template>
<script setup>
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'
defineProps({
    rows: {
      type: Array,
      required: true
    }
})
const router = useRouter()
const OnClickEnvio = (EnvioId) => {
  router.push({ name: 'pharmasan.inventario.operaciones-stock.recepcion-tecnica.enivio', params: { EnvioId } })
}
</script>

<style scoped>

</style>
